
import React, { FC } from "react"
interface Props {
}
const InvalidUser: FC<Props> = (props) => {
    return (<div>
        Sie sind nicht berechtigt diese Seite anzuzeigen
    </div>)
}

export default InvalidUser