import React, { useState, useEffect } from "react"
import './App.css'
import { AccountInfo } from '@azure/msal-browser';
import { publicClientApplication, scopes } from "./provider/publicClientApplication"
import { AuthenticationContext } from "./provider/context"
import Credit from './components/uploadCredit'
import Cancel from './components/uploadCancel'
import InvalidUser from './components/invalidUser'
import { BrowserRouter, Route, Routes } from 'react-router-dom';


const App: React.FC = () => {
  const [accountInfo, setAccountInfo] = useState(null as null | AccountInfo)
  const [paramStripe, setparamStripe] = useState("")

  useEffect(() => {
    (async () => {
      const tokenReponse = await publicClientApplication.handleRedirectPromise()
      if (tokenReponse)
        setAccountInfo(tokenReponse.account)
      else {
        const accounts = publicClientApplication.getAllAccounts()

        if (accounts.length === 1 && accounts[0])
          setAccountInfo(accounts[0])

        if (accounts.length === 0)
          publicClientApplication.loginRedirect({ scopes })
      }
    })()
    const paramCheck = getUrlParams().get("programmeId") || "";
    setparamStripe(paramCheck);
  }, [paramStripe])

  return <>
    {accountInfo &&
      <AuthenticationContext.Provider value={{ accountInfo }}>
        <BrowserRouter>
          {(paramStripe === "" || (paramStripe !== "" && process.env.REACT_APP_PROGRAMMID === paramStripe)) &&
            <Routes>
              <Route path="/" element={<Credit />} />
              <Route path="success" element={<Credit />} />
              <Route path="cancel" element={<Cancel />} />
            </Routes>}
          {(paramStripe !== "" && process.env.REACT_APP_PROGRAMMID !== paramStripe) &&
            <InvalidUser />}
        </BrowserRouter>
      </AuthenticationContext.Provider>}
  </>
}

const getUrlParams = () => {
  if (!window.location.search) return new URLSearchParams(); else return new URLSearchParams(window.location.search);
}

export default App