import PrintRequestDataItem from '../data/printRequestDataItem'
import CheckoutRequestItem from '../data/checkoutRequestItem'

export const jsonMIMEType = "application/json"
export const jsonContentTypeHTTPHeader = ["Content-Type", jsonMIMEType]
export function getOAuthAuthorizationHTTPHeader(accessToken: string) {
    return ["Authorization", "Bearer " + accessToken]
}
export const GetPrintGuthaben = async (accessToken: string): Promise<PrintRequestDataItem> => {
    const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/PrintAccount/getprintguthaben`,
        {
            method: 'get',
            headers: new Headers(
                {
                    'Authorization': 'Bearer ' + accessToken,
                })
        })

    return {
        userValid: response.status !== 403,
        apiConnectionOK: response.status !== 400,
        guthaben: response.status === 204 ? null : await response.text(),
        isLoading: false
    } as PrintRequestDataItem
}

export async function CheckoutWithStripe(accessToken: string, editedItem: CheckoutRequestItem) {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/Checkout/checkoutwithstripe`,
            {
                method: "POST",
                headers: [getOAuthAuthorizationHTTPHeader(accessToken), jsonContentTypeHTTPHeader],
                body: JSON.stringify(editedItem)
            })

        if (!response.ok)
            throw Error(response.statusText)

        return await response.text();
    } catch (error) {
        console.error(error)
    }
}

