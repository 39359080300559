import React, { FC } from 'react';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import DataItem from '../data/dataItem';

interface Props {
    data: DataItem[],
    valueID: string,
    required: boolean,
    className: string
    validityStyles: boolean
    valueChanged: (selectedId: string) => void
}
const dropDownSelect: FC<Props> = (props) => {

    const valueChanged = (event: DropDownListChangeEvent) => {
        if (event.value) {
            props.valueChanged((event.value as DataItem).Value)
        }
    }
    return (
        <DropDownList
            data={props.data}
            textField='Text'
            dataItemKey='Value'
            value={props.data.find(c => c.Value === props.valueID)}
            filterable={false}
            className={props.className}
            onChange={valueChanged}
            required={props.required}
            validityStyles={props.validityStyles}
            style={{ width: '150px' }}

        />)
}

export default dropDownSelect