import React, { FC, useEffect, useCallback, useState, useContext } from "react"
import styles from './styles/uploadCredit.module.css'
import Header from './header'
import PrintRequestDataItem from '../data/printRequestDataItem'
import CheckoutRequestItem from '../data/checkoutRequestItem'
import DataItem from '../data/dataItem'
import { getTokenRedirect } from "../provider/publicClientApplication"
import { AuthenticationContext } from "../provider/context"
import { GetPrintGuthaben, CheckoutWithStripe } from '../data/dataAccess'
import InvalidUser from './invalidUser'
import { ThreeDots } from 'react-loader-spinner'
import DropDownSelect from './dropDown'
import { Button } from '@progress/kendo-react-buttons';

const UploadCredit: FC = () => {
    const [printItem, setPrintItem] = useState({ isLoading: true } as PrintRequestDataItem)
    const [newGuthaben, setNewGuthaben] = useState("0");
    const { accountInfo } = useContext(AuthenticationContext)
    const [werte, setWerte] = useState([] as DataItem[])
    const fetchGuthaben = useCallback(async () => {
        if (accountInfo) {
            const token = await getTokenRedirect(accountInfo);
            const item = await GetPrintGuthaben(token)
            setPrintItem(item)
        }
    }, [accountInfo]);

    useEffect(() => {
        fetchGuthaben()
        let betraege: DataItem[] = [
            { Text: "EUR 5", Value: "5" },
            { Text: "EUR 10", Value: "10" },
            { Text: "EUR 15", Value: "15" },
            { Text: "EUR 20", Value: "20" },
            { Text: "EUR 25", Value: "25" },
            { Text: "EUR 30", Value: "30" },
            { Text: "EUR 35", Value: "35" },
            { Text: "EUR 40", Value: "40" },
            { Text: "EUR 45", Value: "45" },
            { Text: "EUR 50", Value: "50" },]
        setWerte(betraege)
        setNewGuthaben("5")
    }, [fetchGuthaben])

    const submit = useCallback(async () => {
        if (accountInfo) {
            const token = await getTokenRedirect(accountInfo);
            const modifiedItem: CheckoutRequestItem = {
                betrag: newGuthaben,
                ProgrammeId: process.env.REACT_APP_PROGRAMMID ? process.env.REACT_APP_PROGRAMMID : "1"
            }
            const url = await CheckoutWithStripe(token, modifiedItem);
            if (url) { window.location.replace(url) }
        }
    }, [accountInfo, newGuthaben])
    return (<div className={styles.uploadCredit}>
        <Header />

        <div className={styles.body}>
            {printItem.apiConnectionOK === true && printItem.userValid === false && <InvalidUser />}
            {printItem.isLoading === true && <ThreeDots color="#335784" height={80} width={80} />}
            {printItem.apiConnectionOK === true && printItem.userValid === true && printItem.guthaben === null && <div className="full-width text-centered"><h4>No print account found!</h4><h4>Please contact our support team (<a href="mailto:support@fh-krems.ac.at">support@fh-krems.ac.at</a>).</h4></div>}
            {printItem.apiConnectionOK === true && printItem.userValid === true && printItem.guthaben !== null &&
                <div className="full-width text-centered">
                    <h1>Increase print balance</h1>
                    <div className="form-info">
                        <ol>
                            <li>Wählen Sie den Geldbetrag aus, den Sie auf Ihr Druckkonto aufbuchen möchten.</li>
                            <li>Wählen Sie nun „Upload credit“ um Ihr Druckguthaben durch Einzahlung zu erhöhen.</li>
                            <li>Sie werden anschließend auf die Webseite des Zahlungsanbieters weitergeleitet. Folgen Sie den Anweisungen dort, um die Aufbuchung abzuschließen.</li>
                            <li>Bei erfolgreichem Abschluss der Zahlung werden Sie zur Startseite weitergeleitet und sehen unter „Aktueller Equitrac Express-Saldo“ Ihr einbezahltes Guthaben. (Eventuell die Seite aktualisieren!)</li>
                        </ol>
                        <ol>
                            <li>Select the amount of money you want to credit to your printing account.</li>
                            <li>Now select “Upload credit” to increase your print balance by deposit.</li>
                            <li>You will then be redirected to the website of the payment provider. Follow the instructions to complete the charge.</li>
                            <li>If the payment is successfully completed, you will be redirected to the home page and you can see your paid-in balance under "Current Equitrac Express Balance". (If not, please refresh the page!)</li>
                        </ol>
                    </div>
                    <br />
                    <h4>Current Equitrac Express Balance: EUR {printItem.guthaben}</h4>
                    <DropDownSelect data={werte} required={true} validityStyles={false} className={styles.dropWidth} valueID={newGuthaben !== "0" ? newGuthaben : "5"} valueChanged={(newId: string) => { setNewGuthaben(newId) }} />&nbsp;
                    <Button className="button orange right w-100" type="button" themeColor={"primary"} onClick={submit} icon="check">&nbsp;Upload credit</Button>
                </div>}
        </div>
    </div>)
}

export default UploadCredit