import React, { FC, useContext } from "react"
import styles from './styles/uploadCredit.module.css'
import Header from './header'
import { AuthenticationContext } from "../provider/context"
import InvalidUser from './invalidUser'

const UploadCancel: FC = () => {
    const { accountInfo } = useContext(AuthenticationContext)

    return (<div className={styles.uploadCredit}>
        <Header />
        <div className={styles.body}>
            {!accountInfo && <InvalidUser />}
            {accountInfo &&
                <div className="full-width text-centered"><h4>Bezahlvorgang wurde abgebrochen!</h4>
                </div>}
        </div>
    </div>)
}

export default UploadCancel